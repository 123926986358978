import { useMemo } from 'react';
import { areasOptions } from './areas';
import styles from './styles.module.scss';
import AreaCard from './Card';

export default function HomeAreasCards() {
  const Areas = useMemo(
    () =>
      areasOptions.map(area => (
        <AreaCard item={area} key={`area-card-${area.key}`} />
      )),
    [],
  );

  return <div className={styles.container}>{Areas}</div>;
}
