import { useCallback } from 'react';
import { setCookie } from 'cookies-next';
import { CookiesKey } from '@/enums/cookiesKey';
import i18nSetLanguage from 'next-translate/setLanguage';

export function useSetLanguage() {
  const setLanguage = useCallback(async (language: string) => {
    setCookie(CookiesKey.Lang, language, {
      sameSite: 'strict',
      maxAge: 31536000,
    });
    setCookie('NEXT_LOCALE', language);
    await i18nSetLanguage(language);
  }, []);

  return setLanguage;
}
