import { PageProps } from '@/types';

export const navigation: PageProps[] = [
  {
    label: 'solutions_label',
    path: '#solutions',
  },
  {
    label: 'company_label',
    path: '#company',
  },
  {
    label: 'projects_label',
    path: '#projects',
  },
];
