import Section from '@/components/core/Section';
import useTranslation from 'next-translate/useTranslation';
import HomeAreasCards from './Cards';

export default function HomeAreas() {
  const { t } = useTranslation('common');

  return (
    <div
      id="company"
      style={{
        scrollMarginTop: '80px',
      }}
    >
      <Section title={t('home_heading')} description={t('home_description')}>
        <HomeAreasCards />
      </Section>
    </div>
  );
}
