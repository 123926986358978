import { useEffect, useMemo, useState } from 'react';
import { SelectInputProps } from '../props';

import styles from '../styles.module.scss';

export default function SelectInputOptionInput<T, V>({
  open,
  handleToggleFilterDropdown,
  ...props
}: SelectInputProps<T, V> & {
  open: boolean;
  handleToggleFilterDropdown: VoidFunction;
}) {
  const {
    customComponents,
    getOptionLabel,
    options,
    getOptionValue,
    value,
    cardBg,
    disabled,
    secondaryText,
  } = props ?? {};
  const [selectedOption, setSelectedOption] = useState<T | undefined>();
  const foundOption: T | undefined = useMemo(
    () =>
      options?.find(option => {
        return getOptionValue(option) === value;
      }),
    [getOptionValue, options, value],
  );

  const selectedLabel = selectedOption ? getOptionLabel(selectedOption) : '';

  function onChangeOption() {
    if (!foundOption) {
      return;
    }

    setSelectedOption(foundOption);
  }

  useEffect(onChangeOption, [foundOption]);

  return customComponents?.customInput ? (
    customComponents?.customInput({
      handleOpen: handleToggleFilterDropdown,
      open,
      option: selectedOption,
    })
  ) : (
    <div
      className={`${styles['selected-option']} ${cardBg ? styles['selected-option--card-bg'] : ''} ${open ? styles['selected-option--open'] : ''} ${disabled ? styles['selected-option--disabled'] : ''} ${secondaryText ? styles['selected-option--secondary-text'] : ''}`}
      onClick={handleToggleFilterDropdown}
      id="input-select-button"
    >
      <p>{selectedLabel}</p>
      {disabled ? (
        <i className="fa-solid fa-lock" />
      ) : (
        <i className="fas fa-chevron-down" />
      )}
    </div>
  );
}
