import Popover from '@mui/material/Popover';
import { useState } from 'react';
import { RichTooltipProps } from './props';
import styles from './styles.module.scss';

export const RichTooltip = ({
  open,
  onClose,
  children,
  content,
  poppoverOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  className,
}: RichTooltipProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div
      className={`${styles.container}`}
      onClick={handleClick}
      suppressHydrationWarning
    >
      <div>{children}</div>

      <Popover
        onClose={onClose}
        open={open}
        anchorEl={anchorEl}
        BackdropProps={{ invisible: true }}
        className={`${className ? className : ''}`}
        anchorOrigin={poppoverOrigin}
        transformOrigin={transformOrigin}
      >
        {content}
      </Popover>
    </div>
  );
};
