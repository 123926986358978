import Section from '@/components/core/Section';
import useTranslation from 'next-translate/useTranslation';
import HomeGamesCarousel from './Carousel';

export default function HomeGamesSections() {
  const { t } = useTranslation('common');

  return (
    <div
      id="projects"
      style={{
        scrollMarginTop: '80px',
      }}
    >
      <Section
        title={t('games__heading')}
        description={t('games__description')}
      >
        <HomeGamesCarousel />
      </Section>
    </div>
  );
}
