import { ErrorCardProps } from './props';
import styles from './styles.module.scss';
import { Button } from '../Button';
import { useTranslation } from '../../hooks';

export function ErrorCard({
  error,
  refetch,
  customBackground,
  isDefaultColor,
}: ErrorCardProps) {
  const { t } = useTranslation('common');

  return (
    <div
      className={`${styles.container} ${isDefaultColor ? styles['container--default-color'] : ''}`}
      style={{
        backgroundColor: customBackground,
      }}
    >
      <div className={styles.container__spacer} />

      <div
        className={`${styles.container__row} ${styles['container__row--around']}`}
      >
        <div className={styles.container__swoosh}>
          <i className="fad fa-exclamation-triangle" />
        </div>
        <h6 className={styles.container__title}>{t('error_title')}</h6>
      </div>

      <div className={styles.container__spacer} />

      <p className={styles.container__description}>{t('error_description')}</p>

      <div className={styles.container__spacer} />

      {error && (
        <>
          <div className={styles.container__row}>
            <div className={styles.container__code}>
              {error?.response?.data?.statusCode}
            </div>
            <div className={styles.container__divider} />
            <div className={styles.container__message}>
              {error?.response?.data?.message}
            </div>
          </div>

          <div className={styles.container__spacer} />
        </>
      )}

      <Button
        className={styles.container__button}
        onClick={refetch}
        type="button"
      >
        {t('error_button')}
      </Button>
    </div>
  );
}
