import { PropsWithChildren } from 'react';
import styles from './styles.module.scss';
import { FormGroupProps } from './props';
import { useTranslation } from 'react-i18next';
import FormGroupSkeletonLabel from './Skeleton/Label';
import FormGroupSkeletonDescription from './Skeleton/Description';

export function FormGroup({
  label,
  children,
  className = '',
  description,
  loading,
  isLoading,
  optional,
  action,
}: PropsWithChildren<FormGroupProps>): JSX.Element {
  const { t } = useTranslation('common');

  return (
    <div className={`${styles.container} ${className}`}>
      {isLoading ? (
        <FormGroupSkeletonLabel loading={loading} />
      ) : (
        label && (
          <label
            className={`${styles.container__label} ${action ? styles['container__label--action'] : ''}`}
          >
            {label} {action ? action : null}
            {optional && (
              <span
                className={`${styles.container__label} ${styles['container__label--secondary']}`}
              >
                ({t('optional_label')})
              </span>
            )}
          </label>
        )
      )}
      {isLoading ? (
        <FormGroupSkeletonDescription loading={loading} />
      ) : (
        description && (
          <label className={styles['container__description']}>
            {description}
          </label>
        )
      )}
      {children}
    </div>
  );
}
