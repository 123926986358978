import { IconButtonProps } from './props';
import styles from './styles.module.scss';

export function IconButton({
  onClick,
  children,
  id,
  disabled,
  isLoading,
  className,
  iconOnly,
  small,
  isContainerColor,
}: IconButtonProps) {
  if (isLoading) {
    return <div className={styles.skeleton} />;
  }

  return (
    <button
      id={id ?? 'icon-button'}
      className={`${styles.icon} ${className ? className : ''} ${iconOnly ? styles['icon--icon-only'] : ''} ${small ? styles['icon--small'] : ''} ${isContainerColor ? styles['icon--container'] : ''}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
