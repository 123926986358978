import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss';
import { Animation, Image } from '@starsoft/common/components';

export default function HomeHero() {
  const { t } = useTranslation('common');
  return (
    <div className={styles.container}>
      <div className={styles.container__wrapper__content}>
        <div className={styles.container__wrapper}>
          <h1 className={styles.container__wrapper__heading}>
            {t('hero__heading')}
          </h1>
          <h2 className={styles.container__wrapper__description}>
            {t('hero__description')}
          </h2>
        </div>
      </div>
      <Animation
        type="fadeInUp"
        className={`${styles.container__wrapper__content} ${`${styles['container__wrapper__content--image']}`}`}
      >
        <Image
          width={1000}
          height={1000}
          src="/banner/banner.png"
          bundle
          alt="home-hero"
          className={styles.container__wrapper__image}
        />
      </Animation>
    </div>
  );
}
