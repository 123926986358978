import useTranslation from 'next-translate/useTranslation';
import { AreaCardProps } from './props';
import styles from './styles.module.scss';
import { Image } from '@starsoft/common/components';
import { useToggle } from '@starsoft/common/hooks';

export default function AreaCard({ item }: AreaCardProps) {
  const { t } = useTranslation('common');
  const [isHovering, toggleHovering] = useToggle();

  return (
    <div
      className={styles.card}
      onMouseEnter={toggleHovering}
      onMouseLeave={toggleHovering}
    >
      <div className={styles.card__icon__wrapper}>
        {(isHovering && (
          <Image
            width={80}
            height={80}
            src={`${item.icon?.replace('svg', 'gif')}`}
            className={styles.card__icon}
            bundle
            preload
            alt={`item-icon-${item.key}`}
          />
        )) || (
          <Image
            width={80}
            height={80}
            src={`${isHovering ? item.icon?.replace('svg', 'gif') : item.icon}`}
            className={styles.card__icon}
            bundle
            preload
            alt={`item-icon-${item.key}`}
          />
        )}
      </div>
      <h6 className={styles.card__title}>{t(`projects.${item.key}.title`)}</h6>
      <p className={styles.card__description}>
        {t(`projects.${item.key}.description`)}
      </p>
    </div>
  );
}
