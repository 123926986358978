import Link from 'next/link';
import styles from './styles.module.scss';
import Image from 'next/image';
import LanguageButton from '@/components/Language/Button';
import LayoutHeaderNavigation from './Navigation';
import LayoutHeaderMobileNavigation from './MobileNavigation';

export default function Header() {
  return (
    <div className={styles.container}>
      <div className={styles.container__wrapper}>
        <Link href="/">
          <Image
            className={styles.container__wrapper__logo}
            alt="logo starsoft"
            src="/starsoft-logo/starsoft-black.svg"
            width={150}
            height={40}
          />
        </Link>
        <LayoutHeaderNavigation />
        <div className={styles.container__row}>
          <LanguageButton />
          <LayoutHeaderMobileNavigation />
        </div>
      </div>
    </div>
  );
}
