import { useMemo } from 'react';
import { solutionsOptions } from './solutions';
import styles from './styles.module.scss';
import HomeSolutionsCard from './Card';

export default function HomeSolutionsCards() {
  const Solutions = useMemo(
    () =>
      solutionsOptions.map(solution => (
        <HomeSolutionsCard
          item={solution}
          key={`solution-card-${solution.label}`}
        />
      )),
    [],
  );

  return <div className={styles.container}>{Solutions}</div>;
}
