import { AnimationProps } from './props';
import { PropsWithChildren } from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './styles.module.scss';

export function Animation({
  type,
  children,
  triggerOnce = false,
  className,
}: PropsWithChildren<AnimationProps>) {
  const { inView, ref } = useInView({
    initialInView: true,
    triggerOnce,
  });

  return (
    <div
      ref={ref}
      key={`animation-${type}-${!inView}`}
      className={`${styles.container} ${inView ? styles[`container--${type}`] : ''} ${className}`}
    >
      {children}
    </div>
  );
}
