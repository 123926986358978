import { AreasProps } from './props';

export const areasOptions: AreasProps[] = [
  {
    icon: '/areas-icons/icon1.svg',
    key: 'UX/UI',
  },
  {
    icon: '/areas-icons/icon2.svg',
    key: 'Gaming',
  },
  {
    icon: '/areas-icons/icon3.svg',
    key: 'Copy',
  },
  {
    icon: '/areas-icons/icon4.svg',
    key: 'Performance',
  },
  {
    icon: '/areas-icons/icon5.svg',
    key: 'Cryptos',
  },
  {
    icon: '/areas-icons/icon6.svg',
    key: 'Originals',
  },
];
