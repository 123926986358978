import styles from './styles.module.scss';
import { SectionProps } from './props';

export default function Section({
  description,
  title,
  action,
  children,
  useDarkMode,
  adjustLeft,
}: SectionProps) {
  return (
    <div
      data-theme={useDarkMode ? 'dark' : ''}
      className={`${styles.container} ${adjustLeft ? styles['container--adjustLeft'] : ''}`}
      style={{
        backgroundColor: !useDarkMode ? 'transparent !important' : undefined,
      }}
    >
      <h2 className={styles.container__title}>{title}</h2>
      <p className={styles.container__description}>{description}</p>
      {!!action && action}
      <div className={styles.container__content}>{children}</div>
    </div>
  );
}
