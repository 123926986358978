import { useInfiniteScroll } from '@starsoft/common/hooks';
import { OptionsProps } from './props';
import { motion } from 'framer-motion';
import { dropIn } from '../variants';
import styles from './styles.module.scss';
import skeleton from './styles-skeleton.module.scss';
import { memo, useCallback, useEffect, useState } from 'react';
import { ErrorCard } from '@starsoft/common/components';
import { Nullable } from '@starsoft/common/interfaces';
import { KeyboardKey } from '@starsoft/common/enums';

function SelectInputOptions<T, V>({
  getOptionLabel,
  getOptionValue,
  handleClose,
  cardBg,
  customComponents,
  isInverted = false,
  options,
  loadingMore = false,
  loadMore = () => {},
  hasNextPage = false,
  isAsync = false,
  loading = false,
  setValue,
  value,
  error,
  refetch,
  popLayout,
  parentBounding,
}: OptionsProps<T, V>) {
  const { ref } = useInfiniteScroll({
    hasMore: hasNextPage,
    isLoading: loadingMore || loading,
    loadMore,
    condition: isAsync,
  });

  const [highlightedIndex, setHighlightedIndex] =
    useState<Nullable<number>>(null);

  const handleSelectFilter = useCallback(
    (value: T) => {
      setValue(value);
      handleClose();
    },
    [setValue, handleClose],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!options.length) return;

      switch (e.key) {
        case KeyboardKey.ARROW_DOWN:
          setHighlightedIndex(prevIndex =>
            prevIndex === null || prevIndex === options.length - 1
              ? 0
              : prevIndex + 1,
          );
          break;
        case KeyboardKey.ARROW_UP:
          setHighlightedIndex(prevIndex =>
            prevIndex === null || prevIndex === 0
              ? options.length - 1
              : prevIndex - 1,
          );
          break;
        case KeyboardKey.ENTER:
          if (highlightedIndex === null) {
            break;
          }

          e.preventDefault();
          handleSelectFilter(options[highlightedIndex]);
          break;
      }
    },
    [handleSelectFilter, highlightedIndex, options],
  );

  function onMount() {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }

  function onChangeHighlightedIndex() {
    if (!ref.current || !highlightedIndex) {
      return;
    }
    const children: HTMLDivElement[] = Array.from(
      ref.current.children,
    ) as HTMLDivElement[];
    const targetElement: HTMLDivElement = children[highlightedIndex];

    if (!targetElement) {
      return;
    }

    targetElement.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }

  useEffect(onChangeHighlightedIndex, [highlightedIndex, ref]);

  useEffect(onMount, [
    highlightedIndex,
    options,
    handleSelectFilter,
    handleKeyDown,
  ]);

  return (
    <motion.div
      className={`${styles.container} ${isInverted ? styles['container--inverted'] : ''} ${cardBg ? styles['container--card-bg'] : ''} ${popLayout ? styles['container--popLayout'] : ''}`}
      ref={ref}
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
      style={{
        maxWidth: isInverted || !popLayout ? undefined : parentBounding?.width,
        left: isInverted || !popLayout ? undefined : parentBounding?.left,
      }}
    >
      {(loading &&
        (customComponents?.customSkeletonOption
          ? Array.from({ length: 10 }).map((_, index) => {
              return customComponents.customSkeletonOption?.(
                `load-more-options-select-${index}`,
              );
            })
          : Array.from({ length: 10 }).map((_, index) => {
              return (
                <div
                  className={skeleton.container}
                  key={`load-options-select-${index}`}
                >
                  <div className={skeleton.container__text} />
                </div>
              );
            }))) ||
        options.map((option, index) =>
          customComponents?.customOption ? (
            customComponents?.customOption({
              option,
              selected:
                getOptionValue(option) === value || highlightedIndex === index,
              handleSelect: handleSelectFilter,
              key: `select-input-option-${index}`,
            })
          ) : (
            <div
              className={`${styles.container__option} ${
                getOptionValue(option) === value || highlightedIndex === index
                  ? styles['container__option--selected']
                  : ''
              }`}
              key={`select-input-option-${index}`}
              onClick={() => handleSelectFilter(option)}
            >
              {getOptionLabel(option)}
            </div>
          ),
        )}

      {loadingMore &&
        isAsync &&
        (customComponents?.customSkeletonOption
          ? Array.from({ length: 10 }).map((_, index) => {
              return customComponents.customSkeletonOption?.(
                `load-more-options-select-${index}`,
              );
            })
          : Array.from({ length: 10 }).map((_, index) => {
              return (
                <div
                  className={skeleton.container}
                  key={`load-more-options-select-${index}`}
                >
                  <div className={skeleton.container__text} />
                </div>
              );
            }))}
      {error && <ErrorCard error={error} refetch={refetch} isDefaultColor />}
    </motion.div>
  );
}

export default memo(SelectInputOptions) as typeof SelectInputOptions;
