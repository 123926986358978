/* eslint-disable react/destructuring-assignment */
import Head from 'next/head';
import { CoreImageProps } from './props';
import { getBaseImageSize, imageLoader } from './helper';
import styles from './styles.module.scss';
import NextImage from 'next/image';
import { SettingsService } from '@starsoft/common/services/SettingsService';

export function Image(props: CoreImageProps) {
  const imageWidth = props.width
    ? props.width
    : getBaseImageSize('width', props.format);
  const imageHeight = props.height
    ? props.height
    : getBaseImageSize('height', props.format);

  if (!props.src) {
    return (
      <div
        style={
          props.className
            ? {}
            : {
                width: imageWidth,
                height: imageHeight,
                borderRadius: props?.style?.borderRadius ?? '4px',
              }
        }
        className={`${props.className} ${
          props?.secondarySkeleton
            ? styles['skeleton--secondary']
            : styles.skeleton
        }`}
      />
    );
  }

  const url: string = props.src.replace(
    String(SettingsService.env.S3_BUCKET_BASE_URL),
    String(SettingsService.env.CF_DISTRIBUTION),
  );

  return (
    <>
      {props?.preload == true && (
        <Head>
          <link rel="preload" href={url} as="image" fetchPriority="high" />
        </Head>
      )}
      <NextImage
        {...props}
        key={url}
        width={props?.fill ? undefined : imageWidth}
        height={props?.fill ? undefined : imageHeight}
        src={url}
        alt={props?.alt ?? `Image - ${props?.imagePreview}`}
        placeholder={props?.imagePreview ? 'blur' : undefined}
        loader={url.includes('api.') ? undefined : imageLoader}
        blurDataURL={props?.imagePreview}
        loading="lazy"
        style={{
          ...props.style,
          userSelect: 'none',
        }}
        className={`${!!props?.className ? props.className : ''}`}
      />
    </>
  );
}
