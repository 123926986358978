import styles from './styles.module.scss';
import { SolutionCardProps } from './props';
import { Image } from '@starsoft/common/components';
import useTranslation from 'next-translate/useTranslation';

export default function HomeSolutionsCard({ item }: SolutionCardProps) {
  const { t } = useTranslation('common');

  return (
    <div className={styles.card}>
      <div className={styles.card__icon__wrapper}>
        <Image
          width={200}
          height={200}
          src={item.icon}
          alt={`card-icon-${item.label}`}
          className={styles.card__icon}
        />
      </div>
      <div className={styles.card__wrapper}>
        <h5 className={styles.card__wrapper__title}>
          {t(item.label, null, {
            returnObjects: true,
          })}
        </h5>
        <p className={styles.card__wrapper__description}>
          {t(item.description, null, {
            returnObjects: true,
          })}
        </p>
      </div>
    </div>
  );
}
