import useTranslation from 'next-translate/useTranslation';
import styles from './styles.module.scss';
import { useCountUp } from 'use-count-up';
import { useInView } from 'react-intersection-observer';
import { useMemo } from 'react';
import { providers } from './providers';
import { Image } from '@starsoft/common/components';

export default function HomePaymentProviders() {
  const { t } = useTranslation('common');
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  const Providers = useMemo(
    () =>
      providers.map((provider, index) => (
        <Image
          width={300}
          height={150}
          src={provider}
          alt={`provider-image-${provider}`}
          key={`provider-image-${index}`}
          className={`${styles.container__provider} ${index > 9 ? 'desktop_only' : ''}`}
        />
      )),
    [],
  );

  const { value } = useCountUp({
    isCounting: inView,
    start: 0,
    end: 10,
    easing: 'easeInCubic',
  });

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.container__row}>
        <h6 className={styles.container__row__title}>
          {t('payment_providers.title', {
            count: value,
          })}
        </h6>
        <p className={styles.container__row__description}>
          {t('payment_providers.description')}
        </p>
      </div>
      <div className={styles.container__provider__wrapper}>{Providers}</div>
    </div>
  );
}
