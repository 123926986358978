import { useMemo } from 'react';
import styles from './styles.module.scss';
import HomeGamesCarouselColumn from './Column';

export default function HomeGamesCarousel() {
  const Columns = useMemo(
    () =>
      Array.from({ length: 12 }).map((_, index) => (
        <HomeGamesCarouselColumn
          column={index}
          key={`home-carousel-column-${index}`}
        />
      )),
    [],
  );

  return <div className={styles.container}>{Columns}</div>;
}
