import { ImageLoaderProps } from 'next/image';
import { ImageFormat } from './props';
import { SettingsService } from '@starsoft/common/services/SettingsService';

export function getBaseImageSize(
  type: 'width' | 'height',
  format?: ImageFormat,
) {
  if (type === 'width') {
    switch (format) {
      case 'square':
        return 100;
      case 'rectangle':
        return 300;
      default:
        return 100;
    }
  }

  switch (format) {
    case 'square':
      return 100;
    case 'rectangle':
      return 150;
    default:
      return 100;
  }
}

export function imageLoader({ src, width, quality }: ImageLoaderProps) {
  const query = `?w=${width}&quality=${quality || 75}&fm=webp`;
  return `${src.replace(String(SettingsService.env.S3_BUCKET_BASE_URL), String(SettingsService.env.CF_DISTRIBUTION))}${query}`;
}
