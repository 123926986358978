// "use client"
import styles from './styles.module.scss';
import Image from 'next/image';
import AceEditor from 'react-ace';
import { useIsMobile } from '@starsoft/common/hooks';

import 'ace-builds/src-noconflict/mode-typescript';
import 'ace-builds/src-noconflict/theme-github';

export default function CodeComponent() {
  const isMobile = useIsMobile();
  const code = `
async function findTalent(): Promise<Talent> {
  return await talentSearchService.setTechnology('TypeScript')
  .setExperienceLevel('Junior').setLocation('Remote')
  .setAvailability('Full-time').search();
  }

async function assignNewEmployee(
  position: string,
  salary: number): Promise<void> {
  const newTalent: Talent = await findTalent();
  await jobService.assignPosition(position).setSalary(salary)
  .specifyLocation(newTalent.location).work();
  }`;

  return (
    <div className={styles.boxVision}>
      <div className={styles.boxVision__barra}>
        <div className={styles.boxVision__barra__icons}>
          <Image src="/code/paper.svg" alt="paper" width={50} height={50} />
          <Image src="/code/search.svg" alt="search" width={50} height={50} />
          <Image src="/code/ping.svg" alt="ping" width={50} height={50} />
        </div>
        <div className={styles.boxVision__barra__icons}>
          <Image src="/code/user.svg" alt="user" width={50} height={50} />
        </div>
      </div>
      <div className={styles.boxVision__code}>
        <AceEditor
          mode="typescript"
          theme="github"
          name="blah2"
          height="382px"
          value={code}
          style={{
            borderRadius: '0px 8px 8px 0px',
          }}
          readOnly={true}
          width={isMobile ? '300px' : undefined}
          fontSize={isMobile ? 12 : 14}
          lineHeight={24}
          highlightActiveLine={false}
          showGutter={false}
          className={styles.boxVision__code__aceEditor}
          setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: false,
            tabSize: 2,
          }}
          editorProps={{ $blockScrolling: true }}
        />
      </div>
    </div>
  );
}
