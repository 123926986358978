import HomeAreas from '@/components/Home/Areas';
import HomeGamesSections from '@/components/Home/Games';
import HomeHero from '@/components/Home/Hero';
import HomePaymentProviders from '@/components/Home/PaymentProviders';
import HomeProviders from '@/components/Home/Providers';
import HomeSolutions from '@/components/Home/Solutions';
import WorkWithUsSection from '@/components/Home/WorkWithUs';
import Layout from '@/components/Layout';
import { Language } from '@/i18n/languages';
import { generateMetadata } from '@/utils/metadata';
import { Animation } from '@starsoft/common/components';
import { GetStaticPropsContext, GetStaticPropsResult } from 'next';
import loadNamespaces from 'next-translate/loadNamespaces';

export default function Home() {
  return (
    <Layout>
      <HomeHero />
      <Animation type="fadeInUp" triggerOnce>
        <HomeSolutions />
      </Animation>
      <Animation type="fadeInLeft" triggerOnce>
        <HomeAreas />
      </Animation>
      <Animation type="fadeInRight" triggerOnce>
        <HomeProviders />
      </Animation>
      <Animation type="fadeInUp" triggerOnce>
        <HomeGamesSections />
      </Animation>
      <Animation type="fadeInLeft" triggerOnce>
        <HomePaymentProviders />
      </Animation>
      <WorkWithUsSection />
    </Layout>
  );
}

export async function getStaticProps({
  locale,
}: GetStaticPropsContext): Promise<GetStaticPropsResult<{ metadata: string }>> {
  const metadata = await generateMetadata({
    lang: locale as Language,
    path: '/',
    description: 'home_description',
    follow: true,
    title: 'home_heading',
  });

  return {
    props: {
      metadata: JSON.stringify(metadata),
      ...(await loadNamespaces({
        locale,
        pathname: '/',
        nsSeparator: ':',
        keySeparator: '.',
      })),
    },
  };
}
