import { CommonSettingsContext } from '@starsoft/common/context';
import { CommonSettingsContextProps } from '@starsoft/common/context/CommonSettings/props';
import { useContext } from 'react';

export function useSettingsStore() {
  const context: CommonSettingsContextProps = useContext(CommonSettingsContext);

  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }

  return context;
}
