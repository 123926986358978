import { SolutionProps } from './props';

export const solutionsOptions: SolutionProps[] = [
  {
    label: 'solutions.whitelabel.title',
    description: 'solutions.whitelabel.description',
    icon: '/solutions/whitelabel.png',
    link: '/',
  },
  {
    label: 'solutions.turnkey.title',
    description: 'solutions.turnkey.description',
    icon: '/solutions/turnkey.png',
    link: '/',
  },
  {
    label: 'solutions.crypto.title',
    description: 'solutions.crypto.description',
    icon: '/solutions/crypto.png',
    link: '/',
  },
];
