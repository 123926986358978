import { navigation } from '@/constants/navigation';
import styles from './styles.module.scss';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { socials } from '@/constants/social';

export default function Footer() {
  const { t } = useTranslation('common');

  const Navigation = useMemo(
    () =>
      navigation.map(page => (
        <Link
          key={`navigation-item-${page.label}`}
          href={page.path}
          className={styles.container__wrapper__column__link}
        >
          {t(page?.label)}
        </Link>
      )),
    [t],
  );

  const SocialIcons = useMemo(
    () =>
      socials.map(social => (
        <a
          href={social.path}
          target="_blank"
          rel="noreferrer"
          className={styles.container__wrapper__column__icon}
          key={`social-icon-${social.icon}`}
        >
          <i className={social?.icon} />
        </a>
      )),
    [],
  );

  return (
    <footer className={styles.container}>
      <div className={styles.container__wrapper}>
        <div className={styles.container__wrapper__row}>
          <div className={styles.container__wrapper__column}>
            <Image
              className={styles.container__wrapper__column__image}
              alt="logo starsoft"
              src="/starsoft-logo/starsoft-black.svg"
              width={150}
              height={40}
            />
          </div>
          <div className={styles.container__wrapper__column}>
            <h3 className={styles.container__wrapper__column__label}>
              {t('about_us_label')}
            </h3>
            {Navigation}
          </div>
          <div className={styles.container__wrapper__column}>
            <h3 className={styles.container__wrapper__column__label}>
              {t('technology_excellence')}
            </h3>
            <p className={styles.container__wrapper__column__text}>
              {t('technology_description')}
            </p>
          </div>
          <div className={styles.container__wrapper__column}>
            <div className={styles.container__wrapper__column__label}>
              {t('social_media_label')}
            </div>
            <div className={styles.container__wrapper__column__icon__row}>
              {SocialIcons}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
