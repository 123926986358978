import { CommonEnv } from '@starsoft/common/interfaces/common-env';
import getConfig from 'next/config';

export class SettingsService {
  static get env(): CommonEnv {
    const env: Partial<CommonEnv> = {};

    if (typeof window === 'undefined') {
      const envVars = process.env;

      for (const key in envVars) {
        if (key.startsWith('NEXT_PUBLIC_') || key.startsWith('VITE_')) {
          const newKey = key.replace(/^(NEXT_PUBLIC_|VITE_)/, '');
          env[newKey as keyof CommonEnv] = envVars[key];
        }
      }
    }

    //@ts-ignore
    if (typeof import.meta !== 'undefined' && import.meta.env) {
      //@ts-ignore
      const envVars = import.meta.env;
      for (const key in envVars) {
        if (key.startsWith('VITE_')) {
          const newKey = key.replace(/^VITE_/, '');
          env[newKey as keyof CommonEnv] = envVars[key];
        }
      }
    }

    const config = getConfig();

    if (config?.publicRuntimeConfig) {
      for (const key in config?.publicRuntimeConfig) {
        env[key as keyof CommonEnv] = config?.publicRuntimeConfig[key];
      }
    }

    return env as CommonEnv;
  }
}
