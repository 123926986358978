import Section from '@/components/core/Section';
import useTranslation from 'next-translate/useTranslation';
import HomeSolutionsCards from './Cards';

export default function HomeSolutions() {
  const { t } = useTranslation('common');

  return (
    <div
      id="solutions"
      style={{
        scrollMarginTop: '80px',
      }}
    >
      <Section
        title={t('solutions__heading')}
        description={t('solutions__description')}
        useDarkMode
      >
        <HomeSolutionsCards />
      </Section>
    </div>
  );
}
