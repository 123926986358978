import br from '../../public/flags/pt.svg';
import en from '../../public/flags/en.svg';
import zh from '../../public/flags/zh.svg';
import zr from '../../public/flags/ru.svg';
import id from '../../public/flags/id.svg';
import fr from '../../public/flags/fr.svg';
import es from '../../public/flags/es.svg';
import de from '../../public/flags/de.svg';
import { Language } from '@/i18n/languages';

export interface LanguageProps {
  name: string;
  nameAbr: string;
  image: string;
  locale: Language;
}

export const languageMapping: Map<Language, LanguageProps> = new Map([
  [
    Language.Portuguese,
    {
      name: 'pt',
      nameAbr: 'languageAbr.pt',
      image: br.src,
      locale: Language.Portuguese,
    },
  ],
  [
    Language.English,
    {
      name: 'en',
      nameAbr: 'languageAbr.en',
      image: en.src,
      locale: Language.English,
    },
  ],
  [
    Language.Chinese,
    {
      name: 'zh',
      nameAbr: 'languageAbr.zh',
      image: zh.src,
      locale: Language.Chinese,
    },
  ],
  [
    Language.Russian,
    {
      name: 'ru',
      nameAbr: 'languageAbr.ru',
      image: zr.src,
      locale: Language.Russian,
    },
  ],
  [
    Language.Indonesian,
    {
      name: 'id',
      nameAbr: 'languageAbr.id',
      image: id.src,
      locale: Language.Indonesian,
    },
  ],
  [
    Language.French,
    {
      name: 'fr',
      nameAbr: 'languageAbr.fr',
      image: fr.src,
      locale: Language.French,
    },
  ],
  [
    Language.Spanish,
    {
      name: 'es',
      nameAbr: 'languageAbr.es',
      image: es.src,
      locale: Language.Spanish,
    },
  ],
  [
    Language.Dutch,
    {
      name: 'de',
      nameAbr: 'languageAbr.de',
      image: de.src,
      locale: Language.Dutch,
    },
  ],
]);
