import { useMemo } from 'react';
import styles from './styles.module.scss';
import { navigation } from '@/constants/navigation';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { StaggerAnimation } from '@starsoft/common/components';

export default function LayoutHeaderNavigation() {
  const { t, lang } = useTranslation('common');
  const { asPath } = useRouter();
  const Items = useMemo(
    () =>
      navigation.map(page => (
        <Link
          key={`navigation-header-${page.label}`}
          href={page?.path}
          className={`${styles.container__label} ${asPath.includes(page.path) ? styles['container__label--active'] : ''} desktop_only`}
        >
          {t(page?.label)}
        </Link>
      )),
    [t, asPath],
  );

  return (
    //@ts-ignore
    <StaggerAnimation
      key={`stagger-nav-${lang}`}
      direction="row"
      stagger={0.1}
      staggerDirection="up"
      className={styles.container}
    >
      {Items}
    </StaggerAnimation>
  );
}
